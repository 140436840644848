import styled, { css } from 'styled-components';
import { Grid } from '../../../styles/generic/container';
import breakpoints from '../../../styles/settings/breakpoints';
import { gap, spacing, margins } from '../../../styles/settings/spacing';
import { above, below } from '../../../styles/tools/media';
import {
	smBodyRegular,
	smBodyLight,
	xlTitleRegular,
} from '../../atoms/Typography/index.styled';

const Wrapper = styled.section`
	margin-bottom: ${spacing[48]};
	margin-top: ${spacing[48]};

	${below(
	breakpoints[960],
	() => css`
			background-color: var(--secondaryBackground);
			box-shadow: ${gap[16]} ${gap[16]} 0 0 var(--primaryAccent);
		`
)}

	${above(
	breakpoints[960],
	() => css`
			margin-bottom: ${spacing[64]};
			margin-top: ${spacing[64]};
			padding-bottom: ${gap[24]};
		`
)}

	${above(
	breakpoints[1440],
	() => css`
			margin-bottom: ${spacing[80]};
			margin-top: ${spacing[80]};
			padding-bottom: ${gap[32]};
		`
)}
`;

const CollageAndText_inner = styled(Grid)`
	padding-top: ${spacing[48]};
	padding-bottom: ${spacing[48]};

	${above(
	breakpoints[960],
	() => css`
			grid-auto-rows: auto;
			padding-top: 0;
			padding-bottom: 0;

			&::before,
			&::after {
				content: '';
				background-color: var(--secondaryBackground);
			}

			&::before {
				position: relative;
				grid-column: 2 / -1;
				grid-row: 1;
				box-shadow: ${gap[24]} ${gap[24]} 0 0 var(--primaryAccent);
			}

			&::after {
				position: absolute;
				top: 0;
				right: 0;
				width: ${margins[48]};
				height: 100%;
				box-shadow: 0 ${gap[24]} 0 0 var(--primaryAccent);
			}
		`
)}

	${above(
	breakpoints[1440],
	() => css`
			&::before {
				box-shadow: ${gap[32]} ${gap[32]} 0 0 var(--primaryAccent);
			}

			&::after {
				width: calc(${margins[80]} + 0.2vw);
				box-shadow: 0 ${gap[32]} 0 0 var(--primaryAccent);
			}
		`
)}

	${above(
	breakpoints[1920],
	() => css`
			&::after {
				width: calc(${margins[120]} + 0.2vw);
			}
		`
)}
`;

const CollageAndText_collage = styled.div`
	grid-column: 1 / -1;
	margin-bottom: ${gap[24]};

	${above(
	breakpoints[960],
	() => css`
			grid-column: 1 / span 5;
			grid-row: 1;
			margin-bottom: 0;
			padding-top: ${spacing[64]};
			padding-bottom: ${spacing[64]};
		`
)}

	${above(
	breakpoints[1440],
	() => css`
			padding-top: ${spacing[80]};
			padding-bottom: ${spacing[80]};
		`
)}
`;

const CollageAndText_text = styled.div`
	position: relative;
	grid-column: 1 / -1;
	z-index: 1;

	${above(
	breakpoints[960],
	() => css`
			grid-column: 7 / -1;
			grid-row: 1;
			align-self: center;
			padding-top: ${spacing[48]};
			padding-bottom: ${spacing[48]};
		`
)}

	${above(
	breakpoints[1440],
	() => css`
			padding-top: ${spacing[80]};
			padding-bottom: ${spacing[80]};
		`
)}
`;

const CollageAndText_title = styled(xlTitleRegular)`
	margin-bottom: ${gap[24]};
	color: var(--primaryForeground);

	${above(
	breakpoints[960],
	() => css`
			margin-bottom: ${gap[32]};
		`
)}
`;

const CollageAndText_body = styled(smBodyLight)`
	margin-bottom: ${gap[32]};

	&:last-child {
		margin-bottom: 0;
	}

	a {
		color: var(--primaryForeground);
		font-weight: 400;
	}

	${above(
	breakpoints[960],
	() => css`
			margin-bottom: ${gap[40]};
		`
)}
`;

const CollageAndText_bodyBook = styled(smBodyRegular)`
	margin-bottom: ${gap[16]};

	${above(
	breakpoints[960],
	() => css`
			margin-bottom: ${gap[24]};
		`
)}
`;

export {
	Wrapper,
	CollageAndText_inner,
	CollageAndText_collage,
	CollageAndText_text,
	CollageAndText_title,
	CollageAndText_body,
	CollageAndText_bodyBook,
};
