import styled, { css } from 'styled-components';
import breakpoints from '../../../styles/settings/breakpoints';
import { gap } from '../../../styles/settings/spacing';
import { above, below } from '../../../styles/tools/media';
import aspectRatio from '../../../styles/tools/aspectRatio';

const Collage = styled.div`
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	grid-gap: ${gap[8]};

	${above(breakpoints[600], () => css`
		grid-gap: ${gap[16]};
	`)}
`;

const Collage__imgContainer = styled.div`
	display: block;
	position: relative;
	overflow: hidden;
`;

const Collage__imgContainerSmall = styled(Collage__imgContainer)`
	grid-column: span 3;

	& > picture {
		height: 100%;
		padding-top: ${aspectRatio('10:9')};

		${above(breakpoints[960], () => css`
			padding-top: ${props => props.isHero ? aspectRatio('3:2') : aspectRatio('4:5')};
		`)}

		${below(breakpoints[960], () => css`
			padding-top: ${props => props.isHero ? aspectRatio('3:2') : aspectRatio('3:2')};
		`)}

		${below(breakpoints[600], () => css`
			padding-top: ${props => props.isHero ? aspectRatio('1:1') : aspectRatio('3:2')};
		`)}
	}
`;

const Collage__imgContainerRegular = styled(Collage__imgContainer)`
	grid-column: 5 / -1;
	grid-row: 2;
	align-self: start;

	& > picture {
		padding-top: ${aspectRatio('8:7')};

		${above(breakpoints[960], () => css`
			padding-top: ${props => props.isHero ? aspectRatio('3:2') : aspectRatio('5:6')};
		`)}
	}

`;

const Collage__imgContainerMedium = styled(Collage__imgContainer)`
	grid-column: 4 / -1;

	& > picture {
		height: 100%;
		padding-top: ${aspectRatio('5:3')};

		${above(breakpoints[960], () => css`
			padding-top: ${props => props.isHero ? aspectRatio('2:1') : aspectRatio('6:5')};
		`)}
	}
`;

const Collage__imgContainerLarge = styled(Collage__imgContainer)`
	grid-column: span 4;
	grid-row: 2;

	& > picture {
		padding-top: ${aspectRatio('6:5')};

		${above(breakpoints[960], () => css`
			padding-top: ${props => props.isHero ? aspectRatio('10:7') : aspectRatio('9:10')};
		`)}
	}
`;

const Collage__img = styled.img`
	width: 100%;
	display: block;
	position: absolute;
	top: 0;
	left: 0;
`;

export default {
	Collage,
	Collage__imgContainerSmall,
	Collage__imgContainerRegular,
	Collage__imgContainerMedium,
	Collage__imgContainerLarge,
	Collage__img,
};
