import React, { Fragment } from 'react';
import {
	Wrapper,
	CollageAndText_inner,
	CollageAndText_collage,
	CollageAndText_text,
	CollageAndText_title,
	CollageAndText_body,
	CollageAndText_bodyBook,
} from './index.styled';
import Browser from '../../../lib/browser';
import Button from '../../atoms/Button';
import Collage from '../../molecules/Collage';

type collage = {
	src: string;
	alt: string;
};

type copyWithBoldFlag = {
	text: string;
	bold: boolean;
};

type collageText = {
	title: string;
	copyList?: Array<string>;
	copyListWithBoldFlags?: Array<copyWithBoldFlag>;
};

type button = {
	buttonLink: string;
	buttonText: string;
	buttonCategory: string;
	buttonAction: string;
	buttonLabel: string;
	iconName?: string;
};

type CollageAndTextProps = {
	isAnchorLink?: boolean;
	button?: button;
	collageText: collageText;
	collage: Array<collage>;
	anchorId?: string;
};

const renderCopy = (copyWithFlag: copyWithBoldFlag, i: number) => (
	<Fragment key={i}>
		{copyWithFlag.bold && (
			<CollageAndText_bodyBook dangerouslySetInnerHTML={{ __html: copyWithFlag.text }} />
		)}

		{!copyWithFlag.bold && (
			<CollageAndText_body dangerouslySetInnerHTML={{ __html: copyWithFlag.text }} />
		)}
	</Fragment>
);

const CollageAndText = ({
	collageText,
	collage,
	isAnchorLink = false,
	button,
	anchorId,
}: CollageAndTextProps) => (
	<Wrapper as="section" id={anchorId || ''}>
		<CollageAndText_inner>
			<CollageAndText_collage>
				<Collage collage={collage} isHero={false} />
			</CollageAndText_collage>

			<CollageAndText_text>
				<CollageAndText_title as="h2">{collageText.title}</CollageAndText_title>

				{collageText.copyList && (
					<>
						{collageText.copyList[0] !== '' && (
							<CollageAndText_body
								as="span"
								dangerouslySetInnerHTML={{ __html: collageText.copyList[0] }}
							/>
						)}

						<CollageAndText_bodyBook>{collageText.copyList[1]}</CollageAndText_bodyBook>
						<CollageAndText_body>{collageText.copyList[2]}</CollageAndText_body>
					</>
				)}

				{collageText.copyListWithBoldFlags && (
					<>{collageText.copyListWithBoldFlags.map(renderCopy)}</>
				)}

				{button && (
					<Button
						buttonLink={button.buttonLink}
						buttonText={button.buttonText}
						buttonCategory={button.buttonCategory}
						buttonAction={button.buttonAction}
						buttonLabel={button.buttonLabel}
						buttonType="primary"
						callback={event => Browser.handleClick(event, button.buttonLink)}
						iconName={button.iconName || (isAnchorLink ? 'chat' : undefined)}
						target={!isAnchorLink ? '_blank' : undefined}
						rel={!isAnchorLink ? 'noopener noreferrer' : undefined}
					/>
				)}
			</CollageAndText_text>
		</CollageAndText_inner>
	</Wrapper>
);

export default CollageAndText;
