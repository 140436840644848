import Styled from './index.styled';
import CustomImage from '../../atoms/CustomImage';

const Collage = ({ collage, isHero }) => (
	<Styled.Collage>
		<Styled.Collage__imgContainerSmall isHero={isHero}>
			<CustomImage {...collage[0]} width="200" height="143" />
		</Styled.Collage__imgContainerSmall>

		<Styled.Collage__imgContainerMedium isHero={isHero}>
			<CustomImage {...collage[1]} width="300" height="180" />
		</Styled.Collage__imgContainerMedium>

		<Styled.Collage__imgContainerLarge isHero={isHero}>
			<CustomImage {...collage[2]} width="300" height="250" />
		</Styled.Collage__imgContainerLarge>

		<Styled.Collage__imgContainerRegular isHero={isHero}>
			<CustomImage {...collage[3]} width="300" height="250" />
		</Styled.Collage__imgContainerRegular>
	</Styled.Collage>
);

export default Collage;
